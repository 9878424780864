import React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';

import Nps from './pages/Nps'
import NotFound from './pages/NotFound'

function App() {
  
  return (
    <>
      
      <div className="App">
            <div className="container pb-5">
              <div className="row">
                <div className="col-md-12 text-center">
                  <img className="py-4" src={process.env.REACT_APP_COMPANY_LOGO ? process.env.REACT_APP_COMPANY_LOGO : "/logo_verticis.png"} alt="Verticis Web Studio" width="180px"/>
                </div>
              </div>
              <Router>
                <Switch>
                  <Route path="/:client/:nps">
                      <Nps/>
                  </Route>
                  <Route path="*">
                      <NotFound />
                  </Route>
                </Switch>
              </Router>
            </div>
          </div>
    </>
  );
}

export default App;
