import React, { useState, createContext, useEffect}from 'react';
import {useHistory, useParams} from 'react-router-dom';

import api from '../../services/api'

import ContentNps from '../../components/Nps';
import FinishNps from '../../components/Nps/finish';
import Questions from '../../components/Question';
import SplashScreen from '../../components/SplashScreen'


export default function Nps() {

    const [showSplashScreen, setShowSplashScreen] = useState(true);
    const [answers, setAnswers] = useState([]);
    const [observation, setObservation] = useState("");
    const [finishNps, setFinishNps] = useState(false);
    const [score, setScore] = useState(getParameterByName('rating'));

    let history = useHistory();

    let { client, nps } = useParams();
    const [questions, setQuestions] = useState([]); 

    const scoreContext = createContext([score, setScore]);
    const answersContext = createContext([answers, setAnswers]);
    const observationContext = createContext([observation, setObservation]);
    

  function getParameterByName(name) {
        let url = window.location.href;
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return 0;
        if (!results[2]) return 0;
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    async function submitForm(e){
        e.preventDefault();

        if(!window.confirm(`Você tem certeza que quer dar a nota "${score}" ?`)) {
            return false
        }

        setShowSplashScreen(true);
        await api.put(`store/answers/${nps}`, {
          score,
          observation,
          answers
        }).then(response => {
            setFinishNps(true);
            setShowSplashScreen(false);
        })
      }

    useEffect( () => {
        async function getNps(){
        await api.get('find/nps', {
            params: {
            client,
            nps
            }
        }).then((response) => {
            setQuestions(response.data.questions);
            setShowSplashScreen(false);
        }).catch((response) => {
            history.push('/');
        })
        }
        getNps();
    }, [])

    return (
        <>
        <div id="content" className={"row mr-auto ml-auto justify-content-center " + (finishNps ? 'content-finish' : null)}>
            {
                (showSplashScreen)
                ?
                    <SplashScreen />
                :
                    (!finishNps)
                    ?
                        <form className="d-flex w-100 flex-wrap" onSubmit={submitForm}>
                            <ContentNps ratingContext={scoreContext}/>
                            <Questions questions={questions} answersState={answersContext} observationState={observationContext}/>
                        </form>
                    :

                    <FinishNps />
            }
        </div>
        </>
        
  );
}
