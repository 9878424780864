import React, {useContext }from 'react';
import Slider from 'react-input-slider';

import './style.css';

export default function ContentNps({ratingContext}) {
  const [score, setScore] = useContext(ratingContext)

  const npsStyle= {
    backgroundColor: process.env.REACT_APP_COMPANY_COLOR
  };
  
  return (
      <div id="nps" className="col-md-12 col-lg-6 pt-20-sm" style={{backgroundColor: process.env.REACT_APP_COMPANY_COLOR ? process.env.REACT_APP_COMPANY_COLOR : ''}}>
          <div className="text-center">
              <h1 className="text-shadow">Avalie</h1>
              <div className="nps-info mt-4">
                <span className="text-shadow">{process.env.REACT_APP_NPS_QUESTION ? process.env.REACT_APP_NPS_QUESTION : 'Avalie De 0 a 10, quanto você recomendaria a Verticis para um amigo ou empresa parceira?'}</span>
              </div>
          </div>
          <div className="mt-5">
            <div className="big-number text-center">
              <span className="text-shadow">{score}</span>
            </div>
            <div className="nps-range mt-5">
              <Slider
                styles={{
                  track: {
                    backgroundColor: 'rgba(255, 255, 255, .5)',
                    height: 10,
                    width: '100%'
                  },
                  active: {
                    backgroundColor: '#fff'
                  },
                  thumb: {
                    width: 30,
                    height: 30,
                    border: '3px solid #fff',
                    backgroundColor: process.env.REACT_APP_COMPANY_COLOR ? process.env.REACT_APP_COMPANY_COLOR : '#adca40'
                  }
                }}
                xmin={0}
                xmax={10}
                x={score}
                onChange={({ x }) => setScore(x)}
              />
            </div>
          </div>
      </div>
  );
}
