import React from 'react';

import './style.css';

export default function SplashScreen() {
  return (
    <div id="splash-screen" className="d-flex justify-content-center align-items-center">
        <div className="text-center">
            <img src="/loading.gif" alt="Carregando..." width="80px"/>
            <div className="mt-4">
                <p>Carregando, Aguarde</p>
            </div>
        </div>
    </div>
  );
}
