import React from 'react';

// import { Container } from './styles';

export default function FinishNps() {
  return (
    <div className="row justify-content-center">
      <div className="finish-nps">
        <div className="text-center">
            <h1>Obrigado!</h1>
        </div>
        <div className="mt-4 text-center">
            <p>Sua opinião é muito importante para nós!</p>
        </div>
      </div>
    </div>
  );
}
