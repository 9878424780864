import React, {useContext} from 'react';
import './style.css';

export default function Questions({ questions, answersState, observationState}) {
    const [answers, setAnswers] = useContext(answersState);
    const [observation, setObservation] = useContext(observationState);

    const btnSubmitStyle = {
        backgroundColor: process.env.REACT_APP_COMPANY_COLOR
    }
    
    function changeAnswers(id, result){
        let find = false;
        if(answers.length > 0){
            answers.map(answer => {
                if(answer.id == id ) {
                    find = true;
                    return
                }
            });    
        }
        
        if(find){
            setAnswers(answers.filter(answer => {
                if(answer.id === id) answer.result = result
                return answer;
            }))
        }else{
            setAnswers([...answers, {id, result}])
        }
        
    }
  return (
      <>
        <div id="answer" className="col-md-12 col-lg-6 px-4 pb-4 pt-3">
            <div className="text-center">
                <span className="font-weight-normal title-span">Deixe sua opinião</span>
            </div>
            <div className="questions mt-3">
                {
                    (questions)
                    ?
                        questions.map(question => (
                            <div key={question.id} className="question-item d-flex justify-content-between align-items-center">
                                <span>{question.content}</span>
                                <div className="likeDislike d-flex">
                                    <div className="dislike-block">
                                        <input type="radio" name={`question[${question.id}]`} className="d-none" id={`dislike-${question.id}`} value={false} onChange={(e) => changeAnswers(question.id, e.target.value)}/>
                                        <label className="dislike" htmlFor={`dislike-${question.id}`}>
                                            <i className="fa fa-thumbs-down"></i>
                                        </label>
                                    </div>
                                    <div className="like-block">
                                        <input type="radio" name={`question[${question.id}]`} className="d-none" id={`like-${question.id}`} value={true} onChange={(e) => changeAnswers(question.id, e.target.value)}/>
                                        <label className="like" htmlFor={`like-${question.id}`}>
                                            <i className="fa fa-thumbs-up"></i>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        ))
                    :
                    null
                }
                <div className="improvement mt-5">
                    <div className="text-center">
                        <span className="font-weight-normal title-span">Como podemos melhorar?</span>
                    </div>
                    <div className="imporovement-text mt-2">
                        <textarea name="improvement" id="improvement_textarea" placeholder="Como podemos melhorar?" onChange={(e) => setObservation(e.target.value)} value={observation}></textarea>
                    </div>
                </div> 
                <div className="text-center mt-3">
                    <button className="button-submit" type="submit" style={{backgroundColor: process.env.REACT_APP_COMPANY_COLOR ? process.env.REACT_APP_COMPANY_COLOR : ''}}>Enviar</button>
                </div>
            </div>
        </div>
    </>
  );
}
