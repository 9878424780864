import React from 'react';

import './style.css';

export default function NotFound() {
  return (
    <div className="row justify-content-center">
        <div id="content" className="content-not-found">
            <div id="not-found">
                <div className="text-center">
                    <h1>Ops! Avaliação não encontrada</h1>
                </div>
                <div className="mt-4 text-center">
                    <p>Infelizmente ocorreu uma falha, mas não deixe de avaliar as próximas pesquisas. Sua opinião é muito importante para nós.</p>
                </div>
            </div> 
        </div>
    </div>
  );
}
