import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import "font-awesome/css/font-awesome.css";


import './global.css';
import './bootstrap/bootstrap-v4.min.css';
ReactDOM.render(<App />, document.getElementById('root'));

